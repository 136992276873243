
.navbarContainer{
    width: 100%;
}

.logoNavbar{
  width: 300px;
}

a {
    text-decoration: none;
  }
  
  li {
    list-style: none;
  }

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 40px;
    padding-top: 10px;
    color: #fff;
  }
  
  .navLinks a {
    color: #fff;
    cursor: pointer;
  }

  
  /* LOGO */
  .logo {
    font-size: 32px;
  }
  
  /* NAVBAR MENU */
  .menu {
    display: flex;
    gap: 1em;
    font-size: 18px;
  }
  
  .menu li:hover {
    background-color: transparent;
    border-radius: 5px;
    transition: 0.3s ease;
  }
  
  .menu li {
    padding: 10px 14px;
  }
  
  /* DROPDOWN MENU */
  .services {
    position: relative; 
  }
  
  .dropdown {
    background-color: transparent;
    padding: 1em 0;
    position: absolute;
    display: none;
    border-radius: 8px;
    top: 35px;
  }
  
  .dropdown li + li {
    margin-top: 10px;
  }
  
  .dropdown li {
    padding: 0.5em 1em;
    width: 8em;
    text-align: center;
  }
  
  .dropdown li:hover {
    background-color: #10381e;
  }
  
  .services:hover .dropdown {
    display: block;
  }

  input[type=checkbox] {
    display: none;
  } 
  
  /* HAMBURGER MENU */
  .hamburger {
    display: none;
    font-size: 34px;
    user-select: none;
  }
  
  /* APPLYING MEDIA QUERIES */
  @media (max-width: 3000px) {
   .menu {
      display:none;
      position: absolute;
      background-color:rgba(0, 0, 0, 0.705);
      right: 0;
      top:120px;
      text-align: center;
      border-radius: 20px;
      width: 400px;
      max-width: 600px;
      color: white;
      z-index: 1000;
    }
  
    .menu li:hover {
      display: inline-block;
      background-color: rgb(0, 0, 0);
      transition: 0.3s ease;
      padding-block: 10px;
      width: calc(100% - 28px);
      border-radius: 20px;
    }
  
    .menu li + li {
      margin-top: 12px;
    }
  
    input[type=checkbox]:checked ~ .menu {
      display: block;
    }
  
    .hamburger {
      display: block;
    }
  
    .dropdown {
      left: 50%;
      top: 30px;
      transform: translateX(35%);
    }
  
    .dropdown li:hover {
      background-color: #10381e;
    }
  }

  @media screen and (max-width: 450px) {
    .logoNavbar{
      width: 200px;
    }
    .menu{
      width: 100%;
    }
  }