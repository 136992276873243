@font-face {
  font-family: "MontserratAlternatesRegular";
  src: local("MontserratAlternatesRegular"),
   url("./assets/fonts/MontserratAlternates-Regular.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "MontserratAlternatesBoldItalic";
  src: local("MontserratAlternatesBoldItalic"),
   url("./assets/fonts/MontserratAlternates-BoldItalic.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "MontserratAlternatesBold";
  src: local("MontserratAlternatesBold"),
   url("./assets/fonts/MontserratAlternates-Bold.ttf") format("truetype");
  font-weight: bold;
 }

body {
  margin: 0;
  font-family: "MontserratAlternatesRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white
}

h2{
  text-transform: uppercase;
}