.comingContainer{
    display: flex;
    flex-direction: column;
    background: url('../../assets/pattern.svg');
    padding-top: 30px;
    padding-block: 50px;
        
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.divider{
    border: 1px solid #A29167;
    border-style: dashed;
    letter-spacing: 10px;
}

.textComing{
    width: 90%;
    margin-inline: auto;
    color: white;
    text-align: center;
    margin-bottom: 30px;
}

.textComing h2{
    font-family: 'MontserratAlternatesBoldItalic';
}

.cardDatesContainer{
    display: flex;
    flex-direction: row;
    gap: 60px; 
    justify-content: center;
    margin-bottom: 30px;
}

@media screen and (max-width: 800px) {
    .cardDatesContainer{
        gap: 20px; 
    }
}

button{
    background-color: #A29167;
    border-radius: 10px;
    border: none;
    padding-block: 5px;
    padding-inline: 15px;
    color: white;
    text-transform:uppercase;
    font-family: "MontserratAlternatesBold";
    font-size: x-small;
    width: fit-content;
    margin-inline: auto;
    margin-top: 15px;
}
.cardDates{
    display: flex;
    flex-direction: column;
}

.cardDates img{
    width: 250px;
}

.cardDates h3{
    font-family: 'MontserratAlternatesBoldItalic';
}

.buttonBuy{
    cursor: pointer;
    color: white
}

.buttonBuy:hover{
    cursor: pointer;
    color: white;
    padding: 10px;
}

@media screen and (max-width: 950px) {
    .cardDates img{
        width: 200px;
    }
}


@media screen and (max-width: 650px) {
    .cardDatesContainer{
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
    .cardDates img{
        width: 300px;
    }
    button{
        font-size: 17px;
    }
}

@media screen and (max-width: 450px) {
    .cardDates img{
        width: 80%;
        margin-inline: auto;
    }
    button{
        font-size: 15px;
    }
}

  