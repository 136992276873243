
.bannerContainer{
    display: flex;
    justify-content: left;
    gap: 20px;
    width: calc(100% - 40px);
    margin-left: 20px;
    max-width: 1200px;
    padding-bottom: 20px;
    align-items: center;
}
.logoNavbar{
    width: 200px;
  }

.bannerArrow{
    padding-bottom: 20px;
}

.bannerArrow p{
    font-size: small;
    font-family: 'MontserratAlternatesBold';
    margin: 0;
    text-transform: uppercase;
}

.fontBold{
    font-family: 'MontserratAlternatesBold';
}

.bannerImageSectionInfo{
    display: flex;
    flex-direction: row;
    gap: 70px;
    margin-top: 40px;
}

.cornerImage{
    width: 25%;
    max-width: 200px;
}


.middleImage{
    width: 100%;
    max-width: 500px;
}

.BannerinfoSection{
    width: 55%;
    text-align: left;
}

.BannerinfoSection h1, .bannerDatesSection h3, .BannerinfoSection > p{
    font-family: "MontserratAlternatesBold";
}

.BannerinfoSection > p{
    font-size: x-small;
}

.bannerDatesSection{
    display: flex;
    flex-direction: row;
    gap: 40px
}


.arrowDown{
    width: 30px;
    position: relative;
    animation: botar 3s 1000ms infinite;
    margin-bottom: 20px;
}


.BannerimageSection{
    width: 40%;
}

.bannerArrow img{
    margin-top: 10px;
}

.bannerImageHide{
    display: none;
    width: 200px;
}




@keyframes botar{
    0% {top:0px; animation-timing-function:ease-in;}
    30% {top:20px; animation-timing-function:ease-out;}
    45% {top:10px; animation-timing-function:ease-in;}
    60% {top:20px; animation-timing-function:ease-out;}
    68% {top:15px; animation-timing-function:ease-in;}
    76% {top:20px; animation-timing-function:ease-out;}
    86% {top:18px; animation-timing-function:ease-in;}
    88% {top:16px; animation-timing-function:ease-out;}
    87% {top:14px; animation-timing-function:ease-in;}
    88% {top:10px; animation-timing-function:ease-out;}
    90% {top:8px; animation-timing-function:ease-in;}
    92% {top:6px; animation-timing-function:ease-out;}
    94% {top:4px;animation-timing-function:ease-in;}
    100% {top:0px; animation-timing-function:ease-out;}
   }



@media screen and (max-width: 700px) {
    .bannerContainer{
        flex-direction: column;
        position: relative;
    }
    .BannerinfoSection > p{
        font-size: x-small;
    }
    .BannerinfoSection{
        width: 95%;
        text-align: center;
    }
    .bannerDatesSection{
        justify-content: center;
    }
    .bannerImageSectionInfo{
        justify-content: center;
    }
    .bannerContainer{
        gap:0;
        margin-top: -20px;
    }
    .BannerinfoSection h1{
        margin-top: 0;
    }
    .middleImage{
        width: 100%;
        max-width: 500px;
    }
    .BannerimageSection{
        width: 60%;
    }
    .bannerImageHide{
        display: block;
        position: absolute;
        top: 20px
    }
    .logoNavbar{
        display: none;
    }
}

@media screen and (max-width:500px) {
    .BannerimageSection{
        width: 100%;
    }
    .bannerImageSectionInfo{
        gap: 30px;
    }
    .cornerImage{
        width: 30%;
        margin-bottom: 20px;
    }
    .bannerDatesSection{
        flex-direction: column;
        gap: 0;
    }
}


@media screen and (min-width: 1300px) {
    .bannerContainer{
        justify-content: center;
        margin-inline: auto;
        gap: 30%
    }
}
