
.footerCont{
    background-color: black;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block: 30px;
    padding-inline: 5%;
    align-items: center;
}

.logoFooter{
    width: 300px;
}

.organizations{
    width: 100px;
}

.rightsReservedMobile{
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

@media screen and (max-width: 750px) {
    .logoFooter{
        width: 200px;
    }
}

@media screen and (max-width: 600px) {
    .rightsReserved{
        display: none;
    }
    .footerCont{
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
@media screen and (min-width: 601px) {
    
    .rightsReservedMobile{
        display: none;
    }
    
}

@media screen and (max-width: 470px) {
    .logoFooter{
        width: 150px;
    }
    .organizations{
        width: 60px;
    }
}
