
.sponsorsContainer{
    background: url('../../assets/pattern.svg');
    padding-top: 30px;
    padding-block: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.sponsorsContainer h2 {
    font-family: 'MontserratAlternatesBold';
}

.sponsorsContainer div{
    display: flex;
    gap: 80px;
    flex-wrap: wrap;
    justify-content: center;
    margin-inline: 10%;
    margin-top: 50px;
    margin-bottom: 80px;
}

.sponsorsContainer div img{
    width: 85%;
    max-width: 800px;
}

@media screen and (max-width: 560px) {
    .sponsorsContainer{
        padding-top: 20px;
    }
    .sponsorsContainer div{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .sponsorsContainer div img{
        width: 100%;
    }
}